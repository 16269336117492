<template>
  <div class="cabinet-list">
    <template v-if="tableData.length > 0">
      <li class="door-item" v-for="item in tableData" :key="item.deviceId">
        <p class="p-top">
          <span>{{ item.deviceName }}</span>
          <el-tag
            :type="item.deviceStatus ? 'success' : 'danger'"
            v-if="item.gatewayStatus == 1"
            >{{ deviceStatusObj[item.deviceStatus] }}</el-tag
          >
          <el-tag type="danger" v-else>网关离线</el-tag>
        </p>
        <P class="p-mid">位置：{{ item.deviceAddress }}</P>
        <p class="p-mid">外部地址：{{ item.deviceNumber }}</p>
        <div class="p-foot">
          <div class="p-btns">
            <span class="p-title">打开柜门：</span>
            <el-popover placement="right" width="200" trigger="click">
              <el-button
                class="p-size"
                type="text"
                slot="reference"
                @click="onCell(item)"
                >选择货格</el-button
              >
              <template v-if="cabinetItem.length > 0">
                <el-button
                  class="opt-btn"
                  v-for="it in cabinetItem"
                  :key="it.goodsShelvesId"
                  type="primary"
                  size="mini"
                  @click="onOpen(item, it)"
                  >{{ it.goodsShelvesName }}</el-button
                >
              </template>
              <el-empty class="empty" v-else description="暂无货格"></el-empty>
            </el-popover>
          </div>
          <el-button class="p-size" type="text" @click="onOpenAll(item)"
            >打开全部</el-button
          >
        </div>
      </li>
    </template>
    <el-empty class="empty" v-else description="暂无设备信息"></el-empty>
  </div>
</template>

<script>
import {
  getDeviceList,
  getCabinetItem,
  getCabinetOpen,
} from "@/api/device/device";
import {
  deviceStatusObj,
  deviceStatusColor,
  radioObj,
  volumeObj,
} from "@/db/objs";
export default {
  data() {
    return {
      deviceStatusObj,
      deviceStatusColor,
      radioObj,
      volumeObj,
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 0,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        deviceType: "13", //设备类型
        gatewayId: "", //网关
        deviceStatus: "", //设备状态
        bindStatus: "1", //绑定状态
      },
      value: true,
      cabinetItem: [],
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getDeviceList();
  },
  methods: {
    // 【请求】设备列表
    getDeviceList() {
      let data = this.tableParams;
      getDeviceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
        }
      });
    },

    // 【请求】查询售卖柜设备详情
    getCabinetItem(data) {
      getCabinetItem(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.cabinetItem = res.data;
        }
      });
    },

    // 【请求】打开货格
    getCabinetOpen(data) {
      getCabinetOpen(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
        }
      });
    },

    // 【监听】选择货格
    onCell(item) {
      this.getCabinetItem(item);
    },

    // 监听】打开货格
    onOpen(item, it) {
      let data = {
        deviceId: item.deviceId,
        goodsShelvesId: it.goodsShelvesId,
      };
      this.getCabinetOpen(data);
    },

    // 监听】打开全部货格
    onOpenAll(item) {
      let data = {
        deviceId: item.deviceId,
      };
      this.$confirm("你确定要打开全部货格吗?", "打开全部", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.getCabinetOpen(data);
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.cabinet-list {
  display: flex;
  flex-wrap: wrap;
}

.door-item {
  background: #fff;
  display: inline-flex;
  flex-direction: column;
  margin: 0.15rem 0.15rem 0 0;
  border-radius: 0.1rem;
  width: calc((100% - 0.6rem) / 5);
}

.door-item:nth-child(5n) {
  margin-right: 0;
}

p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-top {
  padding: 0.15rem;
  margin-bottom: 0.15rem;
  border-bottom: 1px solid #f0f2f5;
}

.p-mid {
  color: #999;
  padding: 0.05rem 0.15rem;
}

.p-foot {
  padding: 0.15rem;
  margin-top: 0.15rem;
  border-top: 1px solid #f0f2f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-btns {
  flex: 1;
  display: flex;
  align-items: center;
}

.p-title {
  display: inline-block;
  height: 0.42rem;
  line-height: 0.42rem;
}

.p-size {
  font-size: 0.16rem;
}

.opt-title {
  padding: 0.05rem;
  font-weight: bold;
}

.opt-btn {
  margin: 0 0.1rem 0.1rem 0;
  width: 0.9rem;
}

.empty {
  width: 100%;
}
</style>